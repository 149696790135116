import React, { useEffect, useState } from "react";
import NoInternetError from "./singleComponent/NoInternetError";
const NoInternet = (props) => {

  const [hasInternet, setHasInternet] = useState(navigator.onLine);

  window.addEventListener("offline", () => {
    setHasInternet(false);
  });

  window.addEventListener("online", () => {
    setHasInternet(true);
    // window.location.reload(false);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    }


  });

  return <div>{!hasInternet ? <NoInternetError/> : props.children}</div>;
};

export default NoInternet;
