import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import GroupForSmall from "./singleComponent/GroupForSmall";
import { userPath } from "../constants/path";
import ReactLoading from "react-loading";
import {
  Box,
  IconButton,
  SwipeableDrawer,
  TablePagination,
} from "@material-ui/core";
import {
  getArticlesForGroup_request,
  getArticlesForPage_request,
  getArticles_request,
  searchAllArticle_request,
} from "../actions/article.action";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryForSuppCmp_request,
  getSuppForCmp_request,
} from "../actions/category.actions";
import { getGroups_request } from "../actions/group.action";
import Article from "./singleComponent/Article";
import SupplierSbarTitle from "./singleComponent/SupplierSbarTitle";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _KeyboardArrowLeft = _interopRequireDefault(
  require("@material-ui/core/internal/svg-icons/KeyboardArrowLeft")
);
var _KeyboardArrowRight = _interopRequireDefault(
  require("@material-ui/core/internal/svg-icons/KeyboardArrowRight")
);

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        sx={{ padding: 0 }}
      >
        <img src="double-left.png" style={{ height: "1.5rem", padding: 0 }} />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {React.createElement(_KeyboardArrowLeft.default, null)}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {React.createElement(_KeyboardArrowRight.default, null)}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <img src="double-right.png" style={{ height: "1.5rem" }} />
      </IconButton>
    </Box>
  );
}

const Order = (props) => {
  const dispatch = useDispatch();

  const company = useSelector((state) => state.companyReducer.selectedCompany);
  const shop = useSelector((state) => state.companyReducer.selectedShop);
  const articles = useSelector((state) => state.articleReducer.articles);
  const suppliers = useSelector((state) => state.orderReducer.suppliers);
  const categories = useSelector((state) => state.orderReducer.categories);
  const allGroups = useSelector((state) => state.groupReducer.groups);
  const baseRoute = useSelector((state) => {
    let url = state.articleReducer.articles?.first_page_url?.replace(
      /(https:\/\/laravel\.mms\.mils\.me)|(http:\/\/api\.mmsnew\.test)/,
      ""
    );
    return url?.slice(0, -1);
  });

  const searchInput = useRef("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);

  let storageCompany = JSON.parse(localStorage.getItem("company"));
  let storageShop = JSON.parse(localStorage.getItem("shop"));

  useEffect(() => {
    dispatch(getSuppForCmp_request(storageCompany.value));
    dispatch(getArticles_request(articles.current_page));
    dispatch(getGroups_request());
    setIsLoading(false);
  }, []);

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const shoppingCartFun = () => {
    let path = userPath.shoppingCart;
    props.history.push(path);
  };

  const showGroup = (supplier_id) => {
    if (company.value !== undefined) {
      dispatch(getCategoryForSuppCmp_request(0, 0));
      dispatch(getCategoryForSuppCmp_request(company.value, supplier_id));
    } else {
      storageCompany = JSON.parse(localStorage.getItem("company"));
      localStorage.setItem("supplier_id", supplier_id);
      dispatch(getCategoryForSuppCmp_request(0, 0));
      dispatch(
        getCategoryForSuppCmp_request(storageCompany.value, supplier_id)
      );
    }
    searchInput.current.value = "";
  };

  const setActivePage = (current_page) => {
    dispatch(getArticlesForPage_request(baseRoute + current_page));
  };

  //get articles for group - small company
  const articlesForGroup = (group_id) => {
    searchInput.current.value = "";
    dispatch(getArticlesForGroup_request(group_id, 1));
    closeDrawer();
  };

  const search = () => {
    if (searchInput.current.value.length > 0) {
      dispatch(searchAllArticle_request(searchInput.current.value, 1));
    } else {
      dispatch(getArticles_request(1));
      dispatch(getGroups_request());
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="wrapper bckOrders">
          <div
            style={{
              width: "50px",
              height: "50px",
              marginLeft: "45%",
              paddingTop: "50%",
            }}
          >
            <ReactLoading type="spin" style={{ textAlign: "left" }} />
          </div>
        </div>
      ) : (
        <>
          <nav className="navbar navStyle">
            <div className="container-fluid">
              <div className="navbar-header">
                <div className="input-group divSearch">
                  <input
                    className="form-control searchField"
                    placeholder="Naziv"
                    type="text"
                    ref={searchInput}
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text searchSpan "
                      style={{
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                      }}
                    >
                      <img
                        className="imgStyle "
                        src="s.png"
                        alt="user"
                        onClick={search}
                      ></img>
                    </span>
                  </div>
                </div>
              </div>
              <ul className="nav navbar-right">
                <li>
                  <button
                    className="btn headerBtn shadow"
                    type="button"
                    id="sidebarCollapse"
                    onClick={openDrawer}
                  >
                    <i className="fas fa-align-left"></i>
                  </button>
                </li>
                <li>
                  <button
                    className="btn headerBtn shadow"
                    type="button"
                    onClick={() => shoppingCartFun()}
                  >
                    <i className="fas fa-shopping-cart"></i>
                  </button>
                </li>
              </ul>
            </div>
          </nav>

          <div className="wrapper bckOrders">
            <SwipeableDrawer
              id="sidebar"
              anchor="left"
              open={showDrawer}
              onClose={closeDrawer}
              onOpen={openDrawer}
              PaperProps={{
                style: { backgroundColor: "#fff1d8" },
              }}
            >
              <div className="form-group sbHeaderDetails ">
                <div className="input-group">
                  <div className="input-group-prepend setSpanHeight">
                    <span className="input-group-text noBorder">
                      <img
                        className="imgStyle setMarginSpan"
                        src="c.png"
                        alt="user"
                      ></img>
                    </span>
                  </div>
                  <input
                    className="form-control sidebarInput shadow"
                    placeholder="Firma"
                    value={
                      company.label !== undefined
                        ? company.label
                        : storageCompany.label
                    }
                    disabled
                    style={{ backgroundColor: "white" }}
                  />
                </div>
              </div>

              <div className="form-group sidebarForm">
                <div className="input-group">
                  <div className="input-group-prepend setSpanHeight">
                    <span className="input-group-text noBorder">
                      <img
                        className="imgStyle setMarginSpan"
                        src="shop.png"
                        alt="user"
                      ></img>
                    </span>
                  </div>
                  <input
                    className="form-control sidebarInput shadow"
                    placeholder="Objekat"
                    value={
                      shop?.label !== undefined ? shop.label : storageShop.label
                    }
                    disabled
                    style={{ backgroundColor: "white" }}
                  />
                </div>
              </div>

              <div
                className="form-group sbHeaderDetails "
                style={{ marginTop: "-14px" }}
              >
                <div className="input-group">
                  <div className="input-group-prepend setSpanHeight">
                    <span className="input-group-text noBorder">
                      <img
                        className="imgStyle setMarginSpan"
                        src="dsc2.png"
                        alt="user"
                      ></img>
                    </span>
                  </div>
                  <input
                    className="form-control sidebarInput shadow"
                    placeholder="Firma"
                    value={
                      company.discount !== undefined
                        ? company.discount + "%"
                        : storageCompany.discount + "%"
                    }
                    disabled
                    style={{ backgroundColor: "white" }}
                  />
                </div>
              </div>
              <ul className="list-unstyled" style={{ fontSize: "12px" }}>
                <h6 className="titleCategory">Kategorije proizvoda</h6>

                {company.value !== undefined
                  ? company.value === "L001" ||
                    company.value === "V003" ||
                    company.value === "D020" ||
                    company === "F030"
                    ? suppliers.map((supp) => {
                        return (
                          <SupplierSbarTitle
                            key={supp.id}
                            supp_id={supp.supplier_id}
                            supplier_name={supp.supplier_name}
                            showGroup={() => showGroup(supp.supplier_id)}
                            categories={categories}
                            supplier_id={supp.supplier_id}
                          />
                        );
                      })
                    : // add groups for small company
                      allGroups.map((group) => {
                        return (
                          <GroupForSmall
                            key={group.id}
                            group_id={group.group_id}
                            group_name={group.group_name}
                            getArticles={() => articlesForGroup(group.group_id)}
                          />
                        );
                      })
                  : storageCompany.value === "L001" ||
                    storageCompany.value === "V003" ||
                    storageCompany.value === "D020" ||
                    storageCompany === "F030"
                  ? suppliers.map((supp) => {
                      return (
                        <SupplierSbarTitle
                          key={supp.id}
                          supp_id={supp.supplier_id}
                          supplier_name={supp.supplier_name}
                          showGroup={() => showGroup(supp.supplier_id)}
                          categories={categories}
                          supplier_id={supp.supplier_id}
                        />
                      );
                    })
                  : // add groups for small company
                    allGroups.map((group) => {
                      return (
                        <GroupForSmall
                          key={group.id}
                          group_id={group.group_id}
                          group_name={group.group_name}
                          getArticles={() => articlesForGroup(group.group_id)}
                        />
                      );
                    })}
              </ul>
            </SwipeableDrawer>

            <table className=" table table-striped col-sm-12">
              <thead className="tableHeader">
                <tr className="sortCursor">
                  <th className="thCartName">Naziv</th>
                  <th className="thCart">Lager</th>
                  <th className="thCart">
                    <span className="fa fa-sort spanEuro"></span>
                    &euro;
                  </th>
                  <th className="thCart">Kol.</th>
                  <th className="thCart"></th>
                </tr>
              </thead>
              <tbody>
                {articles.data !== undefined ? (
                  articles.data.map((article) => {
                    return (
                      <Article
                        key={article.id}
                        id={article.article_id}
                        name={article.article_name}
                        count={article.count}
                        price={article.price}
                        tax={article.tax}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td>Podaci se učitavaju!</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  {articles?.data?.length && (
                    <TablePagination
                      count={articles.total}
                      page={articles.current_page - 1}
                      onPageChange={(event, newPage) => {
                        setActivePage(newPage + 1);
                      }}
                      rowsPerPage={50}
                      rowsPerPageOptions={[]}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(Order);
