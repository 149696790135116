export const companyConstants = {
    GETCOMPANY_REQUEST: "GET_COMPANY_REQUEST",
    GETCOMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
    GETCOMPANY_FAILURE: "GET_COMPANY_FAILURE",
    GETSHOPSFORCOMPANY_REQUEST: "GET_SHOPS_FOR_COMPANY_REQUEST",
    GETSHOPSFORCOMPANY_SUCCESS: "GET_SHOPS_FOR_COMPANY_SUCCESS",
    GETSHOPSFORCOMPANY_FAILURE: "GET_SHOPS_FOR_COMPANY_FAILURE",
    GETSHOPSFORUSER_REQUEST: "GET_SHOPS_FOR_USER_REQUEST",
    GETSHOPSFORUSER_SUCCESS: "GET_SHOPS_FOR_USER_SUCCESS",
    GETSHOPSFORUSER_FAILURE: "GET_SHOPS_FOR_USER_FAILURE",
    ADDCOMPANY_SUCCESS: "ADD_COMPANY_SUCCESS",
    ADDSHOP_SUCCESS: "ADD_SHOP_SUCCESS",

    RESETSELECTEDCOMPANY_SUCCESS: "RESET_SELECTED_COMPANY_SUCCESS",
    RESETSELECTEDSHOP_SUCCESS: "RESET_SELECTED_SHOP_SUCCESS"

}