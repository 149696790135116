export const financeConstants = {
    GETBALANCE_REQUEST: "GET_BALANCE_REQUEST",
    GETBALANCE_SUCCESS: "GET_BALANCE_SUCCESS",
    GETBALANCE_FAILURE: "GET_BALANCE_FAILURE",

    GETBALANCEOUT_REQUEST: "GET_BALANCE_OUT_REQUEST",
    GETBALANCEOUT_SUCCESS: "GET_BALANCE_OUT_SUCCESS",
    GETBALANCEOUT_FAILURE: "GET_BALANCE_OUT_FAILURE",

    GETANALYTICALCARD_REQUEST: "GET_ANALYTICAL_CARD_REQUEST",
    GETANALYTICALCARD_SUCCESS: "GET_ANALYTICAL_CARD_SUCCESS",
    GETANALYTICALCARD_FAILURE: "GET_ANALYTICAL_CARD_FAILURE"
}