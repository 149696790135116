export const scheduleConstants = {
    WRITE_SCHEDULE_HIST_REQUEST: "WRITE_SCHEDULE_HIST_REQUEST",
    WRITE_SCHEDULE_HIST_SUCCESS: "WRITE_SCHEDULE_HIST_SUCCESS",
    WRITE_SCHEDULE_HIST_FAILURE: "WRITE_SCHEDULE_HIST_FAILURE",

    CHECKSCHEDULEFORVENDORANDDATE_REQUEST: "CHECK_SCHEDULE_FOR_VENDOR_AND_DATE_REQUEST",
    CHECKSCHEDULEFORVENDORANDDATE_SUCCESS: "CHECK_SCHEDULE_FOR_VENDOR_AND_DATE_SUCCESS",
    CHECKSCHEDULEFORVENDORANDDATE_FAILURE   : "CHECK_SCHEDULE_FOR_VENDOR_AND_DATE_FAILURE",

    RESTART_SCHEDULE: "RESTART_SCHEDULE",
    RESETWRITTENVALUE: "RESET_WRITTEN_VALUE"

}