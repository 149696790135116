export const storeSurvey = {
    GETSTORESURVEY_REQUEST: "GET_STORESURVEY_REQUEST",
    GETSTORESURVEY_SUCCESS: "GET_STORESURVEY_SUCCESS",
    GETSTORESURVEY_FAILURE: "GET_STORESURVEY_FAILURE",

    POSTSTORESURVEY_REQUEST: "POST_STORESURVEY_REQUEST",
    POSTSTORESURVEY_SUCCESS: "POST_STORESURVEY_SUCCESS",
    POSTSTORESURVEY_FAILURE: "POST_STORESURVEY_FAILURE",

    GETSTORESURVEYADMIN_REQUEST: "GET_STORESURVEY_ADMIN_REQUEST",
    GETSTORESURVEYADMIN_SUCCESS: "GET_STORESURVEY_ADMIN_SUCCESS",
    GETSTORESURVEYADMIN_FAILURE: "GET_STORESURVEY_ADMIN_FAILURE",

    POSTSTORESURVEYADMIN_REQUEST: "POST_STORESURVEY_ADMIN_REQUEST",
    POSTSTORESURVEYADMIN_SUCCESS: "POST_STORESURVEY_ADMIN_SUCCESS",
    POSTSTORESURVEYADMIN_FAILURE: "POST_STORESURVEY_ADMIN_FAILURE",

    RESETWRITTENVALUE: "RESET_WRITTEN_VALUE",

    GETSSFORDATEANDCOM_REQUEST : "GET_SS_FOR_DATE_AND_COM_REQUEST",
    GETSSFORDATEANDCOM_SUCCESS : "GET_SS_FOR_DATE_AND_COM_SUCCESS",
    GETSSFORDATEANDCOM_FAILURE : "GET_SS_FOR_DATE_AND_COM_FAILURE",

    GETSSFORDATEANDCOMANDCMPANDSHOP_REQUEST : "GET_SS_FOR_DATE_AND_COM_AND_CMP_AND_SHOP_REQUEST",
    GETSSFORDATEANDCOMANDCMPANDSHOPT_SUCCESS : "GET_SS_FOR_DATE_AND_COM_AND_CMP_AND_SHOP_SUCCESS",
    GETSSFORDATEANDCOMANDCMPANDSHOP_FAILURE : "GET_SS_FOR_DATE_AND_COM_AND_CMP_AND_SHOP_FAILURE",

    RESETSTORESURVEYVALUE: "RESET_STORE_SURVEY_VALUE"
}