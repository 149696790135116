export const shoppingCart = {
    ADDITEM_REQUEST: "ADD_ITEM_REQUEST",
    ADDITEM_SUCCESS: "ADD_ITEM_SUCCEESS",
    ADDITEM_FAILURE: "ADD_ITEM_FAILURE",

    DELETEITEM_REQUEST: "DELETE_ITEM_REQUEST",
    DELETEITEM_SUCCESS: "DELETE_ITEM_SUCCESS",
    DELETEITEM_FAILURE: "DELETE_ITEM_FAILURE",
    
    CHANGEQUANTITY_REQUEST: "CHANGE_QUANTITY_REQUEST",
    CHANGEQUANTITY_SUCCESS: "CHANGE_QUANTITY_SUCCESS",
    CHANGEQUANTITY_FAILURE: "CHANGE_QUANTITY_FAILURE",

    CHECKITEM_SUCCESS: "CHECK_ITEM_SUCCESS",
    RESETSHOPPINGCART_SUCCESS: "RESET_SHOPPING_CART_SUCCESS"
}